.overlay {
    display: none;
    transform: translateY(100%);
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.overlay--active {
    display: flex;
}

.modal {
    width: 100vw;
    height: 100vh;
    background: var(--main-accent-color);
    color: #fff;
    display: flex;
    flex-flow: column;
    /* overflow: hidden; */
    overflow-y: auto;
}
.modal__content {
    padding: 1rem;
    text-align: left;
}

.modal__content h1,
.modal__content h2,
.modal__content h3,
.modal__content p {
    max-width: 75ch;
    font-size: 1.5em;
    padding-left: 3vw;
}

.modal__content ul {
    max-width: 85ch;
}

.modal__content > h1,
h2,
h3 {
    line-height: 1.6;
}

.modal__content h1 {
    margin: 0;
    font-size: 2.4em;
    text-transform: uppercase;
}

.modal__content h2 {
    margin: 0;
    font-size: 1.7em;
}

.modal__content p {
    line-height: 1.2;
    /* padding: 2vh; */
}

.modal__content a {
    line-height: 1.2;
    color: #fff;
    cursor: pointer;
}
