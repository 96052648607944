#overlay-button {
    position: sticky;
    float: right;
    right: 2em;
    top: 3em;
    z-index: 5;
    cursor: pointer;
    user-select: none;
}
#overlay-button span {
    height: 4px;
    width: 35px;
    border-radius: 5px;
    background-color: black;
    position: relative;
    display: none;
    transition: all 0.2s ease-in-out;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    -moz-transition: transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
}

@media only screen and (max-width: 768px) {
    #overlay-button span {
        display: flex;
    }
}
#overlay-button span:before {
    top: -10px;
    visibility: visible;
}
#overlay-button span:after {
    top: 10px;
}
#overlay-button span:before,
#overlay-button span:after {
    height: 4px;
    width: 35px;
    border-radius: 2px;
    background-color: black;
    position: absolute;
    content: '';
    transition: all 0.2s ease-in-out;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    -moz-transition: transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
}
#overlay-button:hover span,
#overlay-button:hover span:before,
#overlay-button:hover span:after {
    background: #333332;
}

input[type='checkbox'] {
    display: none;
}
input[type='checkbox']:checked ~ #overlay {
    visibility: visible;
    right: 0;
}
input[type='checkbox']:checked ~ #overlay-button:hover span,
input[type='checkbox']:checked ~ #overlay-button span {
    background: transparent;
}
input[type='checkbox']:checked ~ #overlay-button span:before {
    transform: rotate(45deg) translate(7px, 7px);
    opacity: 1;
}
input[type='checkbox']:checked ~ #overlay-button span:after {
    transform: rotate(-45deg) translate(7px, -7px);
}

#overlay {
    height: 100vh;
    width: 100vw;
    right: -100vw;
    background: var(--main-accent-color);
    z-index: 2;
    visibility: hidden;
    position: fixed;
    transition: 0.8s;
    -webkit-transition: -webkit-transform 0.8s ease-in-out;
    -moz-transition: transform 0.8s ease-in-out;
    -o-transition: transform 0.8s ease-in-out;
    animation-timing-function: ease-in-out;
}
#overlay.active {
    visibility: visible;
}
#overlay ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    padding-left: 0;
    list-style-type: none;
}
#overlay ul li {
    padding: 1em;
}
#overlay ul li button {
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    background-color: transparent;
    border: none;
    font-family: var(--main-font);
    font-weight: 600;
}
#overlay ul li button:hover {
    color: #333332;
}
