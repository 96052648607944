.cls-1,
.cls-2,
.cls-3 {
    fill: none;
}
.cls-4 {
    clip-path: url(#clippath);
}
.cls-5 {
    fill: #1e816d;
}
.cls-2 {
    stroke: #1e816d;
    stroke-width: 50px;
}
.cls-2,
.cls-3 {
    stroke-miterlimit: 10;
}
.cls-3 {
    stroke: #1d816d;
    stroke-width: 42px;
}

.loader-logo-wrapper {
    background-color: var(--main-bg-color);
    position: fixed;
    z-index: 50;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-logo {
    width: 50vmin;
}
