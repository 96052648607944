.artist-card {
    position: relative;
    text-align: start;
    color: white;
    border: none;
    background: none;
    padding: 0;

    display: block;
    width: 42%;
    margin: 0 0 14%;
    float: left;
    height: auto;
    clear: left;
    cursor: pointer;
    z-index: 0;

    overflow: hidden;
}

.artist-card a {
    color: white;
    transition: 0.5s ease-in-out;
    padding: 0 2%;
    font-size: 1.6rem;
}

/* .artist-card a:hover {
    font-size: 3vw;
} */

.right {
    float: right;
    clear: right;
}

@media only screen and (max-width: 768px) {
    .artist-card {
        width: 100%;
        float: none;
        clear: none;
    }

    .right {
        float: none;
        clear: none;
    }
}

.artist-img {
    /* opacity: 0; */
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    vertical-align: bottom;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    position: relative;
    z-index: -1;
    /* transform: translateY(30px); */
}

.artist-description {
    position: absolute;
    bottom: 1em;
    left: 1em;
    display: none;
    font-family: adelle-sans, sans-serif;
    font-size: 1rem;
}

.artist-card:hover > .artist-description {
    display: block;
}

.artist-mag {
    letter-spacing: 0.3em;
    font-size: 1.6vw;
    margin-bottom: 0.2rem;
}

.artist-name {
    border-bottom: 1px solid;
    padding-bottom: 3px;
    font-weight: 800;
    font-size: 2.5em;
}

.smaller-artist-name {
    font-size: 2em;
}

.artist-overlay {
    position: absolute;
    background-color: #1e816ddd;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: 0.5s ease;
    z-index: -1;
}

.artist-card:hover .artist-overlay {
    width: 100%;
    /* opacity: 0.5; */
}

.fa-icons {
    padding: 0.3rem;
    font-size: 2.5vw;
}
.fa-icons > * {
    /* margin-right: 1em; */
    margin-right: 0.2em;
}

.fa-icons > *:last-child {
    margin-right: 0;
}

.management-row {
    /* background-color: #ffffff; */
    display: flow-root;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 15rem;
    padding-right: 15rem;
}

.management-row > .artist-card:first-child {
    margin-top: 14%;
}

@media only screen and (max-width: 1200px) {
    .management-row {
        padding-left: 10rem;
        padding-right: 10rem;
    }
}

@media only screen and (max-width: 992px) {
    .management-row {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

@media only screen and (max-width: 768px) {
    .management-row {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .artist-description {
        font-size: 2em;
    }

    .artist-mag {
        font-size: 0.5em;
    }

    .artist-name {
        font-size: 1.5em;
    }

    .fa-icons {
        font-size: 1em;
    }

    .smaller-artist-name {
        font-size: 1em;
    }
}
