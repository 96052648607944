.App {
    text-align: center;
}

.App-header {
    background-color: var(--main-bg-color);
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 5vmin);
    color: black;
}

.content-wrap {
    max-width: 100%;
}

.man-section {
    padding-top: 10rem;
}

.lab-section {
    padding-top: 20vh;
}

.team-section {
    padding-top: 10vh;
}

.highlight::before,
a::before {
    transform: scaleX(0);
    transform-origin: bottom right;
}

.highlight:hover::before,
a:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.highlight::before,
a::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    border-radius: 5px;
    background: hsl(155, 61%, 66%);
    z-index: -1;
    transition: transform 0.3s ease;
}

a {
    position: relative;
}

@media only screen and (max-width: 768px) {
    .man-section {
        padding-top: 0;
    }

    .lab-section {
        padding-top: 0;
    }

    .lab-section a {
        font-size: medium;
        font-weight: bold;
    }

    .team-section {
        padding-top: 0;
    }

    .app-footer {
        padding-bottom: 10vh;
    }
}


