/* Halloween BG */

.dark-bg {
    background-color: #161616 !important;
}

.link-red::before {
    background-color: #a00a !important;
}

.red-hover:hover {
    color: #a00 !important;
}

/* Ghoststuff */
.ghost-wrap {
    position: absolute;
}

.ghost {
    position: absolute;
    z-index: 80;
    transform-origin: center;
    width: 90px;
    margin: 20px 0 0 -45px;
}
.ghost__eyes,
.ghost__mouth {
    position: absolute;
    z-index: 80;
    width: 15px;
    height: 15px;
    top: 34px;
    left: 50%;
    transform: translate(-50%);
    border-radius: 50px;
    background: #161616;
    margin-left: -20px;
    transform-origin: center;
}
.ghost__eyes {
    box-shadow: 40px 0 0 #161616;
}
.ghost__mouth {
    margin: 0;
    top: 60px;
    transform: scale(0);
    border-radius: 20px 20px 12px 12px;
    width: 20px;
    transform-origin: center bottom;
    overflow: hidden;
}
.ghost__tail {
    position: absolute;
    z-index: 79;
    top: 82px;
    height: 55px;
    width: 100%;
    filter: url(#goo);
}
.ghost__tail:before {
    content: '';
    background: #fff;
    position: absolute;
    bottom: 35px;
    left: 0;
    height: 100px;
    width: 100%;
    border-radius: 40px 40px 5px 5px;
}
.ghost__rip {
    width: 15px;
    height: 28px;
    background: #fff;
    position: absolute;
    top: 15px;
    left: 0;
    box-shadow: -62px 0 0 #fff, -31px 0 0 #fff, 31px 0 0 #fff, 62px 0 0 #fff,
        93px 0 0 #fff;
    border-radius: 50%;
    -webkit-animation: ghost-rips 1.2s linear infinite;
    animation: ghost-rips 1.2s linear infinite;
}

@-webkit-keyframes ghost-rips {
    0% {
        left: 0;
        top: 12px;
    }
    50% {
        left: 31px;
        top: 20px;
    }
    100% {
        left: 62px;
        top: 12px;
    }
}

@keyframes ghost-rips {
    0% {
        left: 0;
        top: 12px;
    }
    50% {
        left: 31px;
        top: 20px;
    }
    100% {
        left: 62px;
        top: 12px;
    }
}
