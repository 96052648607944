/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0; /* ensures it’s fixed to the left edge of the screen */
}

/* Only for styling */
.nav_content {
    background-color: var(--main-bg-color);
    box-shadow: 0px 13px 18px -8px rgba(0, 0, 0, 0.1);
    padding: 0.25em 1em 0.25em 1em;
    text-decoration: none;
    text-align: center;
    display: flex;
    z-index: 5;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .nav_content {
        display: none;
    }
}
