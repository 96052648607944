.title-wrap {
}

.site-title {
    font-size: 15vw;
    color: var(--main-accent-color);
    text-shadow: 0.7rem 0.4rem 0 #000000;
    /* font-family: adelle-sans, sans-serif; */
    font-family: var(--logo-font);
    font-weight: 600;
    font-style: normal;
    margin: 0;
    padding: 1rem;
}

@media only screen and (max-width: 992px) {
    .site-title {
        text-shadow: 0.4rem 0.4rem 0 #000000;
    }
}

@media only screen and (max-width: 768px) {
    .site-title {
        text-shadow: 0.3rem 0.3rem 0 #000000;
    }
}

@media only screen and (max-width: 576px) {
    .site-title {
        text-shadow: 0.2rem 0.2rem 0 #000000;
    }
}

.site-title:hover {
    color: #1e816d;
}
