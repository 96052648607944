.CookieConsent {
    position: fixed;
    background: #fff;
    box-shadow: 5px 5px 30px 1px;
    display: flex;
    flex-direction: column;
    padding: 5rem;
    line-height: 1.7;
    font-size: 1.1rem;
    z-index: 50;
    /* text-align: center; */
    max-width: 45vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.CookieConsent button {
    border: 0;
    font-size: 2rem;
    font-weight: 600;
    padding: 2rem;
    margin: 20px;
    color: #fff;
    background: var(--main-accent-color);
}

@media (max-width: 768px) {
    .CookieConsent {
      max-width: 80vw;
      padding: 2rem;
    }
  }
