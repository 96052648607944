.app-footer {
    background-color: var(--main-accent-color);
    color: var(--main-bg-color);
    /* height: 50vh; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    text-align: start;
    padding-top: 4rem;
    position: relative;
    z-index: 0;
    box-shadow: 0px -10px 30px rgb(0 10 0 / 60%);;
}

.app-footer .site-title {
    color: var(--main-bg-color);
    /* float: right; */
    /* padding-right: 60px; */
    font-size: 10vw;
    padding: 0;
    transform: translateY(1rem);
}

.footer-section h1 {
    font-size: 2rem;
    position: relative;
    padding-inline-start: .5rem;
    padding-inline-end: .5rem;
}

.footer-section a {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
}

.footer-section:first-child {
    margin-top: 2rem;
}

.footer-logo-wrapper {
    /* text-align: end; */
    transform: translateY(-70px);
}

.footer-logo-wrapper p {
    color: var(--main-bg-color);
    transform: translate(0.5em, -1.8em);
    font-size: 1.09vw;
}

@media only screen and (max-width: 992px) {
    .footer-section h1 {
        font-size: 1.5rem;
        margin-bottom: 1em;
    }

    .footer-section a h1 {
        margin-bottom: 0;
    }

    .footer-section a {
        font-size: 1.5rem;
    }

    .footer-logo-wrapper {
        transform: translateY(-60px);
    }
}

@media only screen and (max-width: 768px) {
    .app-footer {
        flex-direction: column;
        text-align: center;
        padding-top: 0;
    }

    .app-footer .site-title {
        transform: translateY(0);
    }

    .footer-logo-wrapper {
        transform: translateY(0);
    }

    .footer-logo-wrapper p {
        transform: translate(0, 0);
    }
}
