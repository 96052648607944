.container {
    width: 35vw;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
}

.flexbox {
    display: flex;
}

.anim-heading-line {
    font-family: 'Helvetica Neue', 'Helvetica', Arial, Verdana, sans-serif;
    text-transform: uppercase;
    line-height: 0.75;
    letter-spacing: -0.01em;
    margin-top: 0.3;
    margin-bottom: 0.3;
    font-size: 5vw;
    /* transform: translateY(100%); */
    /* opacity: 0; */
}

.hr-line {
    position: absolute;
    transform: translateX(-110%);

    background: #fff;
    width: 100%;
    height: 0.5vw;
}

.team-section .hr-line {
    background: #fff;
}

.v-section .hr-line {
    background: #000;
}

.c2,
.c3 {
    position: relative;
    display: flex;
    align-items: center;
}

.c1 {
    flex-grow: 0;
    padding-right: 20px;
}

.c2 {
    margin-left: 10px;
    flex-grow: 3;
}

.c3 {
    flex-grow: 3;
    margin-right: 10px;
}

.c4 {
    flex-grow: 0;
    padding-left: 20px;
}

.c1,
.c4 {
    padding: 10px;
}

.c1,
.c2,
.c3,
.c4 {
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .container {
        width: 60vw;
    }
}
