#navbar {
    overflow: hidden;
    background-color: var(--main-bg-color);
    text-decoration: none;
    text-align: center;
    max-height: 8vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* margin: 0 25px 0 25px; */
    width: 100%;
}

#navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

#navbar li {
    display: inline;
    padding: 14px;
}

#navbar button {
    background-color: transparent;
    border: none;
    color: black;
    padding: 5px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: var(--logo-font);
    font-weight: bold;
    transition: 0.3s;
}

#navbar button:hover {
    color: var(--main-accent-color);
}

#navbar img {
    height: 3em;
}

@media only screen and (max-width: 768px) {
    #navbar_content {
        display: none;
    }
}
