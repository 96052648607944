@import url('https://fonts.googleapis.com/css?family=Signika+Negative:300,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@300;400&family=Exo:wght@300;400&family=Roboto+Mono:wght@300;400&family=Ubuntu:wght@300&display=swap');
@import url('https://use.typekit.net/tiz3nor.css');

:root {
    --main-bg-color: #fff;
    --main-accent-color: #1e816d;
    /* --main-font: 'Signika Negative', sans-serif; */
    --main-font: 'Helvetica Neue', 'Helvetica', Arial, Verdana, sans-serif;
    --logo-font: 'adelle-sans', sans-serif;
    cursor: default;
}

a {
    cursor: default;
}

h1 {
    margin: 0;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: 'Helvetica Neue', 'Helvetica', Arial, Verdana, sans-serif;
    /* font-family: 'Signika Negative', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
