.lab-section {
    /* background-color: var(--main-accent-color); */
    align-items: unset;
    display: block;
    flex-direction: unset;
    color: white;
    padding-bottom: 20vh;
    position: relative;
    z-index: 0;
}

.section-header {
    text-align: left;
    padding-left: 4em;
    padding-top: 3em;
}

.section-header > h1 {
    font-size: 5rem;
    padding-bottom: 1em;
}

.section-label::after {
    background-color: #fff;
    content: '';
    display: inline-block;
    height: 8px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    margin-left: 60px;
}

.section-header-test {
    font-size: 5rem;
    padding-bottom: 1em;
    font-family: 'Helvetica Neue', 'Helvetica', Arial, Verdana, sans-serif;
    text-transform: uppercase;
    line-height: 0.75;
    letter-spacing: -0.08em;
}

.section-label-test:nth-child(1)::after {
    background-color: #fff;
    content: '';
    display: inline-block;
    height: 8px;
    position: relative;
    vertical-align: middle;
    width: 20%;
    margin-left: 60px;
}

.section-label-test:nth-child(2)::before {
    background-color: #fff;
    content: '';
    display: inline-block;
    height: 8px;
    position: relative;
    vertical-align: middle;
    width: 20%;
    margin-right: 60px;
}

.spotify-wrap {
    opacity: 0;
    z-index: 1;
    width: 70vw;
    /* height: 50%; */
    height: 70vh;
    /* padding: 0 10rem 0 10rem; */
    margin-bottom: 5em;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 5px 5px 5px #333333;
    transition: 2s;
    transform: translateY(70px);
    border-radius: 25px;
}

.spotify-wrap:hover {
    /* height: 100%; */
}

#schickUnsButton{
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 1rem;
}
/* Other Screen Sizes */
@media only screen and (max-width: 1200px) {
    #schickUnsButton{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 992px) {
    #schickUnsButton{
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 768px) {
    #schickUnsButton{
        font-size: 1.7rem;
    }
}

.sub-heading{
    margin-bottom: 1em;
}
