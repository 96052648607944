.team-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 10rem;
    overflow: hidden;
}

.moanna-description {
    text-align: end;
    /* border-left: #000 dotted 2px; */
    overflow: hidden;
    transform: translateY(-100%);
    opacity: 0;
}

.moanna-description h1 {
    text-align: start;
    font-size: 5rem;
    mix-blend-mode: overlay;
    line-height: 0.9;
    padding-left: 5vw;
    opacity: 0;
    transform: translateY(10%);
}

.moanna-description a {
    position: relative;
    font-size: 2rem;
    color: #fff;
    padding: 0.2em;
    margin-left: 4rem;
}

.v-section .moanna-description a {
    color: #000;
}

.moanna-description svg {
    transform: translateY(-25%);
    opacity: 0;
}

.moanna-wrapper {
    max-height: 700px;
    max-width: 500px;
    overflow: hidden;
}

.moanna-wrapper > img {
    object-fit: cover;
    width: 100%;
    transform: translateX(100%);
}

#anna-goller {
    padding-top: 5vw;
    transform: translateX(-100%);
    opacity: 0;
}

#moritz-goller {
    padding-top: 5vw;
    transform: translateX(-100%);
    opacity: 0;
}

.first-hr {
    border-style: none;
    border-top-style: dotted;
    width: 100%;
    border-width: 2px;
}

.team-section .first-hr {
    color: #fff;

}

/* ---------- REVERSE STYLES ---------- */
.team-wrapper.reverse {
    flex-direction: row-reverse;
}

.team-wrapper.reverse .moanna-description {
    text-align: start; 
    border-left: none;
    /* border-right: #000 dotted 2px; */
}

.team-wrapper.reverse .moanna-description h1 {
    text-align: start;   /* or keep start if you prefer left alignment in the reversed layout */
    padding-left: 0;
    padding-right: 5vw;
}

.team-wrapper.reverse .moanna-description a {
    margin-left: 0;
    margin-right: 4rem;
}

.team-wrapper.reverse .moanna-wrapper > img {
    transform: translateX(-100%);
  }
  .team-wrapper.reverse .moanna-description {
    transform: translateY(100%);
  }
  


@media only screen and (max-width: 1200px) {
    .moanna-description h1 {
        font-size: 4.5rem;
    }

    .moanna-wrapper {
        max-height: 600px;
        max-width: 400px;
    }

    .moanna-description a {
        margin-left: 2rem;
    }

    #anna-goller {
        padding-top: 2vw;
    }
}

@media only screen and (max-width: 992px) {
    .moanna-description h1 {
        font-size: 3.5rem;
    }

    .moanna-wrapper {
        max-height: 525px;
        max-width: 350px;
    }

    .moanna-description a {
        margin-left: 1.5rem;
    }

    #anna-goller {
        padding-top: 2vw;
    }
}

@media only screen and (max-width: 768px) {
    .team-wrapper {
        flex-wrap: wrap;
    }
    .team-wrapper.reverse {
        flex-direction: row;
    }
    .moanna-description {
        border-left: none;
        text-align: center;
    }
    .moanna-description h1{
            border-left: none;
            text-align: center;
        
    }

    .team-wrapper.reverse .moanna-description {
        text-align: center; 
        border-left: none;
        border-right: none;
    }

    .moanna-description a {
        padding: 0.2em;
        margin-left: 0;
        font-size: 1.7rem;
    }

    #anna-goller,
    #moritz-goller {
        padding: 3rem 0 2rem 0;
    }

    .team-wrapper.reverse .moanna-description h1 {
        text-align: center;   /* or keep start if you prefer left alignment in the reversed layout */
        padding-left: 0;
        padding-right: 0;
    }
    .team-wrapper.reverse .moanna-description a {
        margin-left: 0;
        margin-right: 0;
    }
}

.team-section{
    color: #fff;
}
