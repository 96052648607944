h1.verlag-title {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: start;
}


/* MEDIA SCREEN SIZES */
/* 1200px */
/* 992px */
/* 768px */
@media only screen and (max-width: 1200px) {
    h1.verlag-title{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 992px) {
    h1.verlag-title{
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 768px) {
    h1.verlag-title{
        font-size: 1rem;
    }
}