.c-button {
    background: none;
    border: 0;
    box-sizing: border-box;
    color: transparent;
    cursor: pointer;
    font-size: 18px;
    left: 90%;
    letter-spacing: 1.5px;
    line-height: 90px;
    outline: none;
    overflow: hidden;
    padding: 10px 0 0;
    position: absolute;
    text-transform: uppercase;
    top: 15%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in;
    width: 100px;
}

.c-button::before,
.c-button::after {
    background-color: white;
    content: '';
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    transform-origin: center left;
    transition: all 0.2s ease-in;
    width: 141.4214px;
    z-index: -1;
}

.c-button::before {
    top: 0;
    transform: rotate(45deg);
}

.c-button::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.c-button:hover {
    color: var(--main-accent-color);
}

.c-button:hover::before,
.c-button:hover::after {
    height: 50px;
    transform: rotate(0deg);
}
