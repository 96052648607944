.ar-wrap {
    font-family: Roboto Mono;
    padding: 2rem;
    /* position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%); */
}

.ar-label-wrap {
    padding: 2rem;
}

.ar-label-wrap a {
    padding-left: 20px;
    padding-right: 20px;
}

.ar-label-wrap:nth-child(1) {
}

.ar-label-wrap:nth-child(2) {
}

.ar-label {
    font-weight: bold;
    font-size: 10rem;
}

.ar-link {
    text-decoration: none;
    color: white;
    font-weight: 100;
    font-size: xxx-large;
    opacity: 0;
}

.ar-label-main {
    font-weight: bold;
    font-size: 10rem;
    color: #fff;
}

.ar-label-secondary {
}
